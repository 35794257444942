.image {
    object-fit: cover !important;
    z-index: 1;
}

.link {
    display: block;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    @media all and (max-width: 500px) {
        border-radius: 5px;
    }

    &:hover {
        background-color: rgba(0,0,0,.2);
    }

    background: linear-gradient(-45deg,#282c35,#919cc6,#282c35);
    background-size: 400% 400%;
    animation: gradient 3s ease infinite;

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
}

.layout {
    &-5 {
        height: 450px;
        display: flex;

        @media all and (max-width: 650px) {
            height: 350px;
        }

        @media all and (max-width: 500px) {
            height: 250px;
        }

        &__col {
            width: 100%;

            &:not(:last-child) {
                margin-right: 10px;

                @media all and (max-width: 450px) {
                    margin-right: 5px;
                }
            }
        }
        
        &__item {

            &-1 {
                height: 100%;
            }

            &-2 {
                height: calc(65% - 10px);
                margin-bottom: 10px;

                @media all and (max-width: 450px) {
                    height: calc(65% - 5px);
                    margin-bottom: 5px;
                }
            }

            &-3 {
                height: 35%;
            }

            &-4 {
                height: calc(35% - 10px);
                margin-bottom: 10px;

                @media all and (max-width: 450px) {
                    height: calc(35% - 5px);
                    margin-bottom: 5px;
                }
            }

            &-5 {
                height: 65%;
            }
        }
    }

    &-6 {
        height: 500px;
        display: flex;

        @media all and (max-width: 650px) {
            height: 350px;
        }

        @media all and (max-width: 500px) {
            height: 250px;
        }

        &__col {
            width: 100%;

            &:not(:last-child) {
                margin-right: 10px;

                @media all and (max-width: 600px) {
                    margin-right: 5px;
                }
            }
        }
        
        &__item {

            &-1 {
                height: calc(65% - 10px);
                margin-bottom: 10px;

                @media all and (max-width: 600px) {
                    height: calc(65% - 5px);
                    margin-bottom: 5px;
                }
            }

            &-2 {
                height: 35%;
            }

            &-3 {
                height: calc(35% - 10px);
                margin-bottom: 10px;

                @media all and (max-width: 600px) {
                    height: calc(35% - 5px);
                    margin-bottom: 5px;
                }
            }

            &-4 {
                height: 65%;
            }

            &-5 {
                height: calc(50% - 5px);
                margin-bottom: 10px;

                @media all and (max-width: 600px) {
                    height: calc(50% - 2.5px);
                    margin-bottom: 5px;
                }
            }

            &-6 {
                height: calc(50% - 5px);

                @media all and (max-width: 600px) {
                    height: calc(50% - 2.5px);
                }
            }
        }
    }

    &-7 {
        height: 450px;
        display: flex;

        @media all and (max-width: 750px) {
            height: 350px;
        }

        @media all and (max-width: 650px) {
            height: 250px;
        }

        @media all and (max-width: 450px) {
            height: 200px;
        }

        &__col {
            width: 100%;

            &:not(:last-child) {
                margin-right: 10px;

                @media all and (max-width: 550px) {
                    margin-right: 5px;
                }
            }
        }
        
        &__item {
            &-1 {
                height: 100%;
            }

            &-2 {
                height: calc(65% - 10px);
                margin-bottom: 10px;

                @media all and (max-width: 550px) {
                    height: calc(65% - 5px);
                    margin-bottom: 5px;
                }
            }

            &-3 {
                height: 35%;
            }

            &-4 {
                height: calc(35% - 10px);
                margin-bottom: 10px;

                @media all and (max-width: 550px) {
                    height: calc(35% - 5px);
                    margin-bottom: 5px;
                }
            }

            &-5 {
                height: 65%;
            }

            &-6 {
                height: calc(50% - 5px);
                margin-bottom: 10px;

                @media all and (max-width: 550px) {
                    height: calc(50% - 2.5px);
                    margin-bottom: 5px;
                }
            }

            &-7 {
                height: calc(50% - 5px);

                @media all and (max-width: 550px) {
                    height: calc(50% - 2.5px);
                    margin-bottom: 5px;
                }
            }
        }
    }

    &-8 {
        height: 450px;
        display: flex;

        @media all and (max-width: 750px) {
            height: 350px;
        }

        @media all and (max-width: 650px) {
            height: 250px;
        }

        @media all and (max-width: 450px) {
            height: 200px;
        }

        &__col {
            width: 100%;

            &:not(:last-child) {
                margin-right: 10px;

                @media all and (max-width: 500px) {
                    margin-right: 8px;
                }
            }
        }
        
        &__item {
            &-1 {
                height: calc(65% - 10px);
                margin-bottom: 10px;

                @media all and (max-width: 500px) {
                    height: calc(65% - 8px);
                    margin-bottom: 8px;
                }
            }

            &-2 {
                height: 35%;
            }

            &-3 {
                height: calc(35% - 10px);
                margin-bottom: 10px;

                @media all and (max-width: 500px) {
                    height: calc(35% - 8px);
                    margin-bottom: 8px;
                }
            }

            &-4 {
                height: 65%;
            }

            &-5 {
                height: calc(50% - 5px);
                margin-bottom: 10px;

                @media all and (max-width: 500px) {
                    height: calc(50% - 4px);
                    margin-bottom: 8px;
                }
            }

            &-6 {
                height: calc(50% - 5px);

                @media all and (max-width: 500px) {
                    height: calc(50% - 4px);
                    margin-bottom: 8px;
                }
            }

            &-7 {
                height: calc(35% - 10px);
                margin-bottom: 10px;

                @media all and (max-width: 500px) {
                    height: calc(35% - 8px);
                    margin-bottom: 8px;
                }
            }

            &-8 {
                height: 65%;
            }
        }
    }
}

.grid {
    &--cols-3 {
        .grid__item {
            height: 300px;

            @media all and (max-width: 650px) {
                height: 200px;
            }

            @media all and (max-width: 450px) {
                height: 150px;
            }
        }

        .grid__col {
            &:not(:last-child) {
                margin-right: 15px;

                @media all and (max-width: 550px) {
                    margin-right: 10px;
                }
            }
        }
    }

    &--cols-2 {
        .grid__item {
            height: 500px;

            @media all and (max-width: 650px) {
                height: 350px;
            }

            @media all and (max-width: 500px) {
                height: 270px;
            }
        }

        .grid__col {
            &:not(:last-child) {
                margin-right: 15px;

                @media all and (max-width: 550px) {
                    margin-right: 10px;
                }
            }
        }
    }

    &--cols-1 {
        .grid__item {
            height: 700px;

            @media all and (max-width: 650px) {
                height: 550px;
            }

            @media all and (max-width: 500px) {
                height: 470px;
            }
        }
    }

    &__item {
        height: 150px;

        @media all and (max-width: 750px) {
            height: 120px;
        }

        @media all and (max-width: 550px) {
            height: 90px;
        }

        @media all and (max-width: 450px) {
            height: 70px;
        }
    }

    &__row {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 10px;

            @media all and (max-width: 550px) {
                margin-bottom: 5px;
            }
        }
    }
    
    &__col {
        width: 100%;

        &:not(:last-child) {
            margin-right: 10px;

            @media all and (max-width: 550px) {
                margin-right: 5px;
            }
        }
    }
}