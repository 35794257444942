@import "../../../../styles/variables";

.content {
  font-size: $post-text-font-size;
  line-height: $post-text-line-height;
  overflow-wrap: break-word;
  word-wrap: break-word;

  &__block {
    &:not(:last-child) {
      margin-bottom: $post-content-block-margin;
    }

    a {
      color: var(--text-link-color);
      text-decoration: none;

      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }

      &:focus {
        text-decoration: underline;
      }
    }

    &--small-bottom-margin:not(:last-child) {
      margin-bottom: 10px;

      @media all and (max-width: 500px) {
        margin-bottom: 5px;
      }
    }
  }
}

.delimiter {
  &:not(:first-child) {
    margin-top: 30px;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &::before {
    display: block;
    content: '***';
    font-size: $post-text-font-size * 2;
    line-height: 1em;
    height: .6em;
    letter-spacing: .2em;
    text-align: center;
  }
}

.embed {
  &__container {
    margin: 0 auto;
  }

  &--provider-twitter, &--provider-instagram {
    .embed__container {
      max-width: 550px;
    }
  }

  &__loader {
    display: none;
  }

  iframe {
    margin: 0 auto;
    display: block;

    &[data-loading='true'] {
      display: none !important;
    }

    &[data-loading='true'] + .embed__loader {
      display: block !important;
    }

    &[data-loading='true'] + .embed__loader + .embed__caption {
      display: none !important;
    }
  }

  &__loader {
    display: none;

    svg {
      animation-name: spin;
      animation-duration: 3000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      display: block;
      margin: 0 auto;
    }
  }


  &__caption {
    font-size: 80%;
    line-height: 1.4;
    margin-top: 5px;
  }
}

.paragraph {
  margin: 0;

  :global {
    code.inline-code {
        background: rgb(59 72 101);
        color: #fff;
        padding: 3px 4px;
        border-radius: 5px;
        margin: 0 1px;
        font-family: inherit;
        font-size: 0.86em;
        font-weight: 500;
        letter-spacing: 0.3px;
    }

    mark.cdx-marker {
      background: rgb(72 131 116);
      color: #fff;
      padding: 3px 0;
    }
}
}

.quote {
  position: relative;
  background-color: rgb(59, 72, 101);
  padding: 40px 40px 35px 80px;
  border-radius: 10px;
  margin: 0;

  @media all and (max-width: 500px) {
    padding-left: 65px;
  }

  &::before {
    content: '';
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAJSwAACUsBBj4EmQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAUvSURBVHic7ZtbaFxFGMd/35KLVnOhtDGoqUkaDBYrmHih3h4KxShS9SGKojYiQWh9EVr0xcuDFWu1IqikPohUUvFOsYJFxVDUB0GhD02lVfFGbWOwiaS3pM3nw5yN67q7Z+bsnDNg+sFwssns/P7fNzNnznxnIqrKfLZcaAGh7UwAQgsIbfM+ADW+GhKRXuAKoANoB+qBn4GNqvqHL04Ffj2wCuiO+G3ANDACDKnqbMkvqmriArQAG4C9gJYpX1TDsNDQC7wC/FlBw9py3080AkREgEHgWaAppnpbEoaFhoXAc8D9FtVby/4lQcS7gN2Uj3ZxeTOFXu8HDjtouKlsW47gq4BxB7ACazw7/5gj/wSwoOoAADcCU47wWaDVk+M5zFx34SvwScV2LeF9mDuqK/xbjz2/LQFfgfVVBQCzrEwkhD/tyfkNCfkKLE8cAKAZ2J8QPAG0e3C+DzidUMPHse3HwHc6Ao8DO4ABoNGD80uAI44afgVeAlYCkjgAwF2O4GGgxdecjzR86MD/C1hn43RsAKKhf8gSPA3c6tPxSEO/g/N7gQsTccrAtzrAB1Nwvhn43ZI/BnQkZpWAL8Os3zbwLb6djzQ8Y8k/AVxTFasE3Ha93QnkUnC+Eftl9+6qeUXwdmDGAvwD0JBS7z9i6fxmL7wi+MuW8IGUnK+3nPuTQLPXAAANwDHL3q9JKQD3WHbAU76YhSmx1cDZxNtGVT1lUS+J3WlRZwp4wRuxIPo7iI/8j6TX+03ASQsNm7xyI3gjZkmJg69Lw/lIwxoL/gxwnk9ufgqsxtyAKtks8K7b+HKyfos6I6p62Cc0H4AbLOp+6RuetyjHeL1F1Xd8s/MB6LWo+17hBxE5V0QuFpEWDzq6MNOwkp0GPijgi4i0iEi3iNjcvEtajYjUAZfG1FNgVEQeB24DlhYKFpFJTN5gP7AHGFbVgw46bDrgK6BHRO7AbHUvAGrz+kTkN+BApGE38L6qnrSB92C39rqUU5hVxeo5HZNe961hHHgeWBzDZjAFeL7MAkNAU4yIz1LUMA7cWykAQynC8+UXoLuCCNesT5KyHagtFYCPMoAr5kXGZSUENGTEV8wO9qziAIxkKGCMovcEmNdWWfEVeLv4QWgB2dli4LWi32XJB+gXkfsKf1HpzW5a5YGCEbA8AH+SaCRmPQLy9qiI5B/CQvAbgbVgngTPCSCgC7gl+jkEH+BBEanPYZcDSMMGomsofgvQl8OkoELYiugaig9wXQ4YDQRvFZF24DvMjSmEXZsD9gWCA3Sq6jHMYaoQ1hVyBMA/u7lQGupCj4C66BpKQ20+AKHm4Fh0DTUCDuVU9Sgm15+1KeYpFEwSJYTtyT+NbQ8A/0lVpwBU9Rvg+wAa5gLwOtlPgzeKPm/LmD8LDBduSz8lu83IUWBR0bb4IuxezPoqb829GIkE9JL8MJJrebFMZmhLhgHo+VcAIgGvZgCeAZaUCUAjcDADDbvmmEUCFmHyd2nCn4hJkN6O/QmVJGWCgtRcKQGX4H4e2LZYHakBHk6JP0VRqr6cgKtxPxccV7baOF+gYZNn/nFg5X84FQRcjnlA8eI8jueJAAEe8tQRR4CbS3JiRNQCT5LsoLRiltYrXRwvoaET+LyKXt8MLCzbvqWI84H1DiPia2BVNY6X0LACc1ze5v40jck+t8W1K1Hj1iYiyzAvUzui3mnB7CX2YTY1o6o66dSoG78WE4ylEb8Tk9uc4wMHVHXGqj3XAPzfbN7/3+CZAIQWENrmfQD+BhZzRpCsAoLYAAAAAElFTkSuQmCC);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    opacity: 0.2;
    left: 15px;
    top: 15px;
  }

  &__container {
    margin: 0;
    display: block;
    margin: 0;
    font-style: normal;
    font-size: 1.2em;
    line-height: 1.3;

    @media all and (max-width: 500px) {
      font-size: 1em;
    }
  }

  &__caption {
    display: block;
    margin-top: 5px;
    font-style: italic;
    font-size: 80%;
  }
}

.list {
  ul, ol {
    padding-left: 25px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.link {
  position: relative;

  &__content {
    display: block;
    padding: 25px;
    color: #ffffff !important;
    text-decoration: none !important;
    background: #3c4865;
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
    border-radius: 10px;
    will-change: filter;
    animation: link-in 450ms 1 cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &__anchor {
    display: block;
    font-size: 15px;
    line-height: 1em;
    color: #b6b6b6 !important;
    border: 0 !important;
    padding: 0 !important;
  }

  &__description {
    margin: 0 0 20px 0;
    font-size: 15px;
    line-height: 1.55em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__title {
    font-size: 17px;
    font-weight: 600;
    line-height: 1.5em;
    margin: 0 0 10px 0;
  }

  &__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 0 0 30px;
    width: 65px;
    height: 65px;
    border-radius: 3px;
    float: right;
  }
}

.personality {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row-reverse;

  &__image-container {
    position: relative;
    overflow: hidden;
    flex: 1 0 200px;
    min-height: 170px;
    margin-right: 20px;
  }

  &__image {
    position: static !important;
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
    display: block;
    border-radius: 10px;
    overflow: hidden;
  }

  &__content {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 1.6rem;
    margin-bottom: 20px;
    font-weight: 600;
  }

  &__description {
    flex: 1 1 100%;
  }

  &__link {
    color: grey !important;
    font-size: 80%;
    margin-top: 10px;
  }

  @media all and (max-width: 650px) {
    &__image-container {
      margin-right: 15px;
    }
  }

  @media all and (max-width: 520px) {
    flex-direction: column;

    &__content {
      margin-bottom: 10px;
      padding: 15px;
    } 

    &__image {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &__image-container {
      flex-basis: auto;
      margin-right: 0;
    }

    background-color: #3b4865;
    border-radius: 10px;

    margin-left: -15px;
    margin-right: -15px;
  }
}