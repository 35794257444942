@import "../../../../styles/variables";

.post {
  @media all and (max-width: 1290px) {
    padding-bottom: 20px;
  }

  &__meta {
    margin-bottom: 5px;
  }

  &__content:not(:first-child) {
    margin-top: 10px;
  }
}

.meta {
  font-size: 0.8rem;
  color: #7a7a7a;
}


.title {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: $post-title-font-size;

  &__translation-icon {
    font-size: 1.5rem;
    margin-right: 8px;
    cursor: pointer;
    outline: none;
  }
}