.text-post {
    border-radius: 10px;

    :global {
        code.inline-code {
            background: rgb(59 72 101);
            color: #fff;
            padding: 3px 4px;
            border-radius: 5px;
            margin: 0 1px;
            font-family: inherit;
            font-size: 0.86em;
            font-weight: 500;
            letter-spacing: 0.3px;
        }

        mark.cdx-marker {
            background: rgb(72 131 116);
            color: #fff;
            padding: 3px 0;
        }
    }

    &__text {
        margin-top: 10px;
        font-size: 1rem;

        a {
            color: var(--text-link-color);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}


.title {
    margin-top: 0px;
    margin-bottom: 0px;

    > a {
        padding-bottom: 5px;
        font-size: 1.2rem;
    }

    &__translation-icon {
        font-size: 1rem;
        margin-right: 5px;
        outline: none;
        cursor: pointer;
    }
}

.quote {
    position: relative;
    margin: 0;
    font-size: 1rem;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 45px;
    padding-right: 20px;
    background-color: rgb(59, 72, 101);

    &__container {
        margin: 0;
    
        &::before {
            content: '';
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAJSwAACUsBBj4EmQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAUvSURBVHic7ZtbaFxFGMd/35KLVnOhtDGoqUkaDBYrmHih3h4KxShS9SGKojYiQWh9EVr0xcuDFWu1IqikPohUUvFOsYJFxVDUB0GhD02lVfFGbWOwiaS3pM3nw5yN67q7Z+bsnDNg+sFwssns/P7fNzNnznxnIqrKfLZcaAGh7UwAQgsIbfM+ADW+GhKRXuAKoANoB+qBn4GNqvqHL04Ffj2wCuiO+G3ANDACDKnqbMkvqmriArQAG4C9gJYpX1TDsNDQC7wC/FlBw9py3080AkREgEHgWaAppnpbEoaFhoXAc8D9FtVby/4lQcS7gN2Uj3ZxeTOFXu8HDjtouKlsW47gq4BxB7ACazw7/5gj/wSwoOoAADcCU47wWaDVk+M5zFx34SvwScV2LeF9mDuqK/xbjz2/LQFfgfVVBQCzrEwkhD/tyfkNCfkKLE8cAKAZ2J8QPAG0e3C+DzidUMPHse3HwHc6Ao8DO4ABoNGD80uAI44afgVeAlYCkjgAwF2O4GGgxdecjzR86MD/C1hn43RsAKKhf8gSPA3c6tPxSEO/g/N7gQsTccrAtzrAB1Nwvhn43ZI/BnQkZpWAL8Os3zbwLb6djzQ8Y8k/AVxTFasE3Ha93QnkUnC+Eftl9+6qeUXwdmDGAvwD0JBS7z9i6fxmL7wi+MuW8IGUnK+3nPuTQLPXAAANwDHL3q9JKQD3WHbAU76YhSmx1cDZxNtGVT1lUS+J3WlRZwp4wRuxIPo7iI/8j6TX+03ASQsNm7xyI3gjZkmJg69Lw/lIwxoL/gxwnk9ufgqsxtyAKtks8K7b+HKyfos6I6p62Cc0H4AbLOp+6RuetyjHeL1F1Xd8s/MB6LWo+17hBxE5V0QuFpEWDzq6MNOwkp0GPijgi4i0iEi3iNjcvEtajYjUAZfG1FNgVEQeB24DlhYKFpFJTN5gP7AHGFbVgw46bDrgK6BHRO7AbHUvAGrz+kTkN+BApGE38L6qnrSB92C39rqUU5hVxeo5HZNe961hHHgeWBzDZjAFeL7MAkNAU4yIz1LUMA7cWykAQynC8+UXoLuCCNesT5KyHagtFYCPMoAr5kXGZSUENGTEV8wO9qziAIxkKGCMovcEmNdWWfEVeLv4QWgB2dli4LWi32XJB+gXkfsKf1HpzW5a5YGCEbA8AH+SaCRmPQLy9qiI5B/CQvAbgbVgngTPCSCgC7gl+jkEH+BBEanPYZcDSMMGomsofgvQl8OkoELYiugaig9wXQ4YDQRvFZF24DvMjSmEXZsD9gWCA3Sq6jHMYaoQ1hVyBMA/u7lQGupCj4C66BpKQ20+AKHm4Fh0DTUCDuVU9Sgm15+1KeYpFEwSJYTtyT+NbQ8A/0lVpwBU9Rvg+wAa5gLwOtlPgzeKPm/LmD8LDBduSz8lu83IUWBR0bb4IuxezPoqb829GIkE9JL8MJJrebFMZmhLhgHo+VcAIgGvZgCeAZaUCUAjcDADDbvmmEUCFmHyd2nCn4hJkN6O/QmVJGWCgtRcKQGX4H4e2LZYHakBHk6JP0VRqr6cgKtxPxccV7baOF+gYZNn/nFg5X84FQRcjnlA8eI8jueJAAEe8tQRR4CbS3JiRNQCT5LsoLRiltYrXRwvoaET+LyKXt8MLCzbvqWI84H1DiPia2BVNY6X0LACc1ze5v40jck+t8W1K1Hj1iYiyzAvUzui3mnB7CX2YTY1o6o66dSoG78WE4ylEb8Tk9uc4wMHVHXGqj3XAPzfbN7/3+CZAIQWENrmfQD+BhZzRpCsAoLYAAAAAElFTkSuQmCC);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            display: inline-block;
            width: 15px;
            height: 15px;
            position: absolute;
            opacity: 0.3;
            left: 10px;
            top: 10px;
        }
    }

    &__caption {
        margin-top: 5px;
        font-style: italic;
        font-size: 80%;
    }

    &__link {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        
        &:hover {
            background-color: #00000014;
            border-radius: 10px;
        }
      }
}

.personality {
    display: flex;
    position: relative;
    overflow: hidden;
    flex-direction: row-reverse;
    border-radius: 10px;
    overflow: hidden;
    
    &__link {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
    }
  
    &__image-container {
      position: relative;
      overflow: hidden;
      flex: 1 0 80px;
      margin-right: 15px;
    }
  
    &__image {
      position: static !important;
      width: 100% !important;
      height: auto !important;
      object-fit: cover;
      display: block;
      border-radius: 10px;;
    }
  
    &__content {
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
    }
  
    &__name {
      font-size: 1.2rem;
      margin-bottom: 10px;
      font-weight: 600;
    }


    &:hover {
        .personality__name {
            color: var(--accent-color);
        }
    }
  
    &__description {
      margin-bottom: 10px;
      flex: 1 1 100%;
    }

    @media all and (max-width: 700px) {
        &__image-container {
            flex-basis: 90px;
        }
    }

    @media all and (max-width: 620px) {
        &__image-container {
            flex-basis: 100px;
        }
    }
  
    @media all and (max-width: 540px) {
      flex-direction: column;
  
      &__content {
        margin-bottom: 10px;
        padding: 10px;
      } 

      &__image {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
  
      &__image-container {
      
        flex-basis: auto !important;
        margin-right: 0px;
      }
  
      background-color: #eeeeee;
      border-radius: 10px;
    }
  }