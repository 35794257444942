@import "../../../../styles/variables";
@import "../../../ui/variables";

.form {
  border-radius: 8px;
  min-height: calc(100%);

  @media all and (max-width: $container-max-width) {
    padding-bottom: 20px;
  }

  @media all and (max-width: 500px) {
    border-radius: 0;
  }

  &__title {
    margin-bottom: $post-content-block-margin;

    textarea {
      margin: 0;
      padding-bottom: 0;
      padding-left: 0;
      border: 0;
      font-size: $post-title-font-size;
      width: 100%;
      font-weight: bold;
      background: transparent;
      color: #ffffff;

      &:focus {
        outline: none;
      }
    }
  }
}

.sidebar {
  position: relative;
  height: calc(100% - 70px);

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &_large {
      font-size: 2em !important;
    }
  }

  &__label {
    font-size: 0.8em;
    text-align: center;
    color: #808080FF;
  }

  &__topContainer {
    height: 100%;
  }

  &__top {

    @media all and (min-width: $max-main-width) {
      position: sticky;
      top: 20px;
    }
  }

  &__bottom {

    @media all and (min-width: $max-main-width) {
      position: sticky;
      bottom: 20px;
    }

  }
}

.modal {
  &__action {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
