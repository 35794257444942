.modal {
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100001;
    background-color: rgb(0 0 0 / 80%);

    &__image {
        object-fit: contain;
        padding: 20px;
    }

    &__loader {
        animation-name: spin;
        animation-duration: 3000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        color: #ffffff;
        font-size: 40px;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
    }
}

html.unscrollable {
    overflow-y: scroll !important;

    body {
        overflow: hidden !important;
    }
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
}