.feed {
    display: block;

    &__date {
        color: #8a8a8a;
        font-size: 0.8rem;
        margin-bottom: 25px;
        text-transform: uppercase;
    }

    &__date-group:not(:first-child) {
        margin-top: 20px;
    }

    &__post {
        margin-bottom: 25px;

        &--images {
            margin-top: 30px;
        }
    }
}