.grid {
    &--cols-5 {
        img {
            height: 180px !important;

            @media all and (max-width: 700px) {
                height: 130px !important;
            }
    
            @media all and (max-width: 600px) {
                height: 100px !important;
            }
    
            @media all and (max-width: 500px) {
                height: 80px !important;
            }

            @media all and (max-width: 400px) {
                height: 70px !important;
            }
        }
    }
    
    &--cols-4 {
        img {
            height: 270px !important;

            @media all and (max-width: 700px) {
                height: 230px !important;
            }
    
            @media all and (max-width: 600px) {
                height: 190px !important;
            }
    
            @media all and (max-width: 500px) {
                height: 150px !important;
            }

            @media all and (max-width: 450px) {
                height: 130px !important;
            }
        }
    }

    &--cols-3 {
        img {
            height: 350px !important;

            @media all and (max-width: 700px) {
                height: 280px !important;
            }
    
            @media all and (max-width: 600px) {
                height: 220px !important;
            }
    
            @media all and (max-width: 500px) {
                height: 180px !important;
            }

            @media all and (max-width: 400px) {
                height: 150px !important;
            }
        }
    }

    &--cols-2 {
        img {
            height: 500px !important;

            @media all and (max-width: 700px) {
                height: 400px !important;
            }
    
            @media all and (max-width: 600px) {
                height: 350px !important;
            }
    
            @media all and (max-width: 480px) {
                height: 280px !important;
            }

            @media all and (max-width: 400px) {
                height: 250px !important;
            }
        }
    }

    &--cols-1 {
        img {
            height: auto !important;
        }
    }

    img {
        object-fit: cover;
        width: 100% !important;
        position: static !important;
        display: block;
      }

    &__row {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 10px;

            @media all and (max-width: 500px) {
                margin-bottom: 5px;
              }
        }
    }
    
    &__col {
        width: 100%;

        &:not(:last-child) {
            margin-right: 10px;

            @media all and (max-width: 500px) {
                margin-right: 5px;
              }
        }
    }
}

.caption {
    font-size: 80%;
    line-height: 1.4;
    margin-top: -5px;

    @media all and (max-width: 500px) {
        margin-top: 0px;
      }
}

.picture {
    &__image {
        border-radius: 10px;
        overflow: hidden;
    
        @media all and (max-width: 500px) {
            border-radius: 5px;
        }

        background: linear-gradient(-45deg,#282c35,#919cc6,#282c35);
        background-size: 400% 400%;
        animation: gradient 3s ease infinite;

        @keyframes gradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
    }

    &--with-background {
      .picture__image {
        padding: 40px;
        height: 700px;
        background-color: #dedede;
  
        @media all and (max-width: 600px) {
          height: 500px;
        }
  
        @media all and (max-width: 500px) {
          height: 400px;
        }
  
        > div, > div > div {
          width: 100%;
          height: 100%;
        }
  
        img {
          object-fit: contain;
          height: 100% !important;
        }
      }
    }
  
    &--with-border {
      .picture__image {
        border: 1px solid #dedede;
      }
    }
}
