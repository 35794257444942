.container {
  left: 0;
  top: 0;
  position: fixed;
  z-index: 500;
  width: 100%;
  height: 100%;

  &_hidden {
    display: none;
  }

  &_modal {
    left: 0;
    top: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 501;
  }
}

.background {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
}

.modal {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  padding: 20px;
  background-color: #292f3e;
}
