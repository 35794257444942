@import "../../../../styles/variables";

.post {
  display: block;
  background-color: #41434e;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  width: 100%;
  overflow: hidden;

  @media all and (max-width: 500px) {
    border-radius: 0;
  }

  &__link {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 0;

    &:active {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.2);
    }

    @media (hover: hover) {
      &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.empty {
  text-align: center;
  color: gray;
}

.title {
  h3 {
    margin: 0px;
  }
}

.paragraph {
  h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
  }

  a {
    color: var(--text-link-color);
    text-decoration: none;
  }
}

.image {
  margin: -20px;

  > img {
    position: static !important;
    width: 100% !important;
    height: auto !important;
    display: block;
  }
}

.quote {
  background-color: var(--quote-bg-color);
  margin: -20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 20px;

  &::before {
    content: '';
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAJSwAACUsBBj4EmQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAUvSURBVHic7ZtbaFxFGMd/35KLVnOhtDGoqUkaDBYrmHih3h4KxShS9SGKojYiQWh9EVr0xcuDFWu1IqikPohUUvFOsYJFxVDUB0GhD02lVfFGbWOwiaS3pM3nw5yN67q7Z+bsnDNg+sFwssns/P7fNzNnznxnIqrKfLZcaAGh7UwAQgsIbfM+ADW+GhKRXuAKoANoB+qBn4GNqvqHL04Ffj2wCuiO+G3ANDACDKnqbMkvqmriArQAG4C9gJYpX1TDsNDQC7wC/FlBw9py3080AkREgEHgWaAppnpbEoaFhoXAc8D9FtVby/4lQcS7gN2Uj3ZxeTOFXu8HDjtouKlsW47gq4BxB7ACazw7/5gj/wSwoOoAADcCU47wWaDVk+M5zFx34SvwScV2LeF9mDuqK/xbjz2/LQFfgfVVBQCzrEwkhD/tyfkNCfkKLE8cAKAZ2J8QPAG0e3C+DzidUMPHse3HwHc6Ao8DO4ABoNGD80uAI44afgVeAlYCkjgAwF2O4GGgxdecjzR86MD/C1hn43RsAKKhf8gSPA3c6tPxSEO/g/N7gQsTccrAtzrAB1Nwvhn43ZI/BnQkZpWAL8Os3zbwLb6djzQ8Y8k/AVxTFasE3Ha93QnkUnC+Eftl9+6qeUXwdmDGAvwD0JBS7z9i6fxmL7wi+MuW8IGUnK+3nPuTQLPXAAANwDHL3q9JKQD3WHbAU76YhSmx1cDZxNtGVT1lUS+J3WlRZwp4wRuxIPo7iI/8j6TX+03ASQsNm7xyI3gjZkmJg69Lw/lIwxoL/gxwnk9ufgqsxtyAKtks8K7b+HKyfos6I6p62Cc0H4AbLOp+6RuetyjHeL1F1Xd8s/MB6LWo+17hBxE5V0QuFpEWDzq6MNOwkp0GPijgi4i0iEi3iNjcvEtajYjUAZfG1FNgVEQeB24DlhYKFpFJTN5gP7AHGFbVgw46bDrgK6BHRO7AbHUvAGrz+kTkN+BApGE38L6qnrSB92C39rqUU5hVxeo5HZNe961hHHgeWBzDZjAFeL7MAkNAU4yIz1LUMA7cWykAQynC8+UXoLuCCNesT5KyHagtFYCPMoAr5kXGZSUENGTEV8wO9qziAIxkKGCMovcEmNdWWfEVeLv4QWgB2dli4LWi32XJB+gXkfsKf1HpzW5a5YGCEbA8AH+SaCRmPQLy9qiI5B/CQvAbgbVgngTPCSCgC7gl+jkEH+BBEanPYZcDSMMGomsofgvQl8OkoELYiugaig9wXQ4YDQRvFZF24DvMjSmEXZsD9gWCA3Sq6jHMYaoQ1hVyBMA/u7lQGupCj4C66BpKQ20+AKHm4Fh0DTUCDuVU9Sgm15+1KeYpFEwSJYTtyT+NbQ8A/0lVpwBU9Rvg+wAa5gLwOtlPgzeKPm/LmD8LDBduSz8lu83IUWBR0bb4IuxezPoqb829GIkE9JL8MJJrebFMZmhLhgHo+VcAIgGvZgCeAZaUCUAjcDADDbvmmEUCFmHyd2nCn4hJkN6O/QmVJGWCgtRcKQGX4H4e2LZYHakBHk6JP0VRqr6cgKtxPxccV7baOF+gYZNn/nFg5X84FQRcjnlA8eI8jueJAAEe8tQRR4CbS3JiRNQCT5LsoLRiltYrXRwvoaET+LyKXt8MLCzbvqWI84H1DiPia2BVNY6X0LACc1ze5v40jck+t8W1K1Hj1iYiyzAvUzui3mnB7CX2YTY1o6o66dSoG78WE4ylEb8Tk9uc4wMHVHXGqj3XAPzfbN7/3+CZAIQWENrmfQD+BhZzRpCsAoLYAAAAAElFTkSuQmCC);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 15px;
    height: 15px;
    position: absolute;
    opacity: 0.3;
    left: 5px;
    top: 5px;
  }

  &--align-center {
    text-align: center;
  }

  blockquote {
    display: block;
    margin: 0;
    font-style: normal;
    font-weight: 600;
  }

  cite {
    display: block;
    margin-top: 10px;
    font-style: italic;
  }
}